import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, IconButton, Button, useMediaQuery } from "@material-ui/core";
import _ from "lodash";
const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
    fontSize: 21,
    marginTop: "20%",
    marginBottom:30,
    fontFamily:"Helvetica"
  },
  subTitle: {
    fontSize: 12,
    marginTop:25,
    letterSpacing: 3
  },
  contact: {
    marginTop: 180,
    "& .MuiButton-label": {
      fontSize: 12,
      letterSpacing: 1.5
    },

    width:180,padding:"1px 10px",
    border: `1px solid white`,
    borderRadius:0,
    "&:hover": {
      backgroundColor: `white`,
      "& .MuiButton-label": {
        color: "black",
      },
    },
  },
}));

const Landing = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
    <div
      className="fadeIn3"
      style={{
        color: "white",
      }}
    >
      <div className={classes.title}>{`P  W  M  C`}</div>
      <hr style={{maxWidth:400}}/>
      <div className={classes.subTitle}>
        PRIVATE WEALTH MANAGEMENT IN CRYPTO
      </div>

    </div>
    <div
      className="fadeIn7">
       <Button
       className={classes.contact}
       variant="outlined"
       onClick={() => {
         window.open("mailto:contact@pwmc.com");
       }}
     >
       Contact
     </Button></div>
     </>
  );
};

export default Landing;
